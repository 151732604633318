import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'rebass/styled-components'

export const Pillar = ({ number, text }) => {
  return (
    <Box variant="pillar">
      <Box variant="pillarNumber">{number}</Box>

      <Text variant="pillarText" as="p">
        {text}
      </Text>
    </Box>
  )
}

Pillar.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string,
}
