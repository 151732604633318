import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

import Container from '../components/container'
import reliefBgDark from '../images/relief-bg-dark.png'

export const ContentSection = ({ children, dark, sx, boxContentSx, ...rest }) => {
  return (
    <Box
      variant={dark ? 'sections.contentDark' : 'sections.content'}
      sx={{
        backgroundColor: dark ? `#003954` : 'white',
        backgroundImage: dark ? `url(${reliefBgDark})` : 'none',
        ...sx,
      }}
      {...rest}
    >
      <Container>
        <Box variant="sections.content.boxContent" sx={{ ...boxContentSx }}>
          {children}
        </Box>
      </Container>
    </Box>
  )
}

ContentSection.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
  sx: PropTypes.object,
  boxContentSx: PropTypes.object,
}

ContentSection.defaultProps = {
  dark: false,
}
