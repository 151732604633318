import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Image, Link as RebassLink } from 'rebass/styled-components'

import arrowRightWhite from '../images/carbon_arrow-right.svg'
import arrowRightBlue from '../images/carbon_arrow-right-blue.svg'

export const HeroLink = ({ children, noArrow, sx, dark, ...rest }) => {
  return (
    <RebassLink variant="heroLink" as={Link} sx={{ ...sx }} {...rest}>
      {children}

      {!noArrow && (
        <Image
          src={dark ? arrowRightBlue : arrowRightWhite}
          alt=""
          aria-hidden
        />
      )}
    </RebassLink>
  )
}

HeroLink.propTypes = {
  children: PropTypes.node.isRequired,
  noArrow: PropTypes.bool,
  sx: PropTypes.object,
  dark: PropTypes.bool,
}
