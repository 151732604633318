/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Box, Card, Heading, Link as RebassLink, Text } from 'rebass/styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Hero } from '../../components/hero'
import { Pillar } from '../../components/pillar'
import { HeroLink } from '../../components/hero-link'
import { ContentSection } from '../../sections/content'
import reliefBg from '../../images/relief-bg.png'

const ArboritecPage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/nabizime-hero-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO
        title="Arboritec"
        description="Kvalita, servis a udržitelnost ve skandinávském stylu"
      />

      <Hero
        image={heroBg}
        bgPosition="center 60%"
        title="Arboritec"
        text="Kvalita, servis a udržitelnost ve skandinávském stylu"
        leadText
      />

      <ContentSection
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url(${reliefBg})`,
          backgroundSize: '100%, 50%',
        }}
      >
        <Box sx={{ position: 'relative', mt: -6 }}>
          <Card
            sx={{
              gridTemplateColumns: ['1fr', 'auto 1fr'],
              gap: 4,
              alignItems: 'center',
            }}
          >
            <StaticImage
              src="../../images/arboritec-logo.png"
              alt=""
              placeholder="none"
              quality={90}
              height={89}
              objectFit="contain"
              objectPosition="0"
            />

            <Text as="p" sx={{ fontSize: '1.125rem', fontWeight: 'bold' }}>
              Švédská společnost Arboritec vznikla v 90. letech s cílem nabízet
              laky a oleje na dřevěné podlahy typické pro stavby tohoto
              severského státu. V&nbsp;průběhu let se z&nbsp;lokální firmy stala
              společnost, která pomáhá dokončovat a udržovat podlahy
              v&nbsp;domech po celém světě.
            </Text>
          </Card>
        </Box>
      </ContentSection>

      <ContentSection sx={{ pt: 0 }}>
        <Heading variant="contentSectionTitle" as="h2">
          Za vším hledej nanotechnologii
        </Heading>

        <Text as="p">
          Co činí všechny výrobky firmy Arboritec jedinečnými? Odpověď
          zní:&nbsp;
          <Link to="/nabizime/nanotechnologie">nanotechnologie</Link>. Do všech
          produktů se přidávají nanotechnologicky vyráběná aditiva, která
          výsledné úpravě zajistí vysokou odolnost, spolehlivou ochranu a krásný
          vzhled.
        </Text>
      </ContentSection>

      <ContentSection dark>
        <Heading variant="contentSectionTitle" as="h2">
          Filozofie Arboritec stojí na třech pilířích
        </Heading>

        <Box variant="pillars">
          <Pillar number={1} text="kvalita produktů" />
          <Pillar number={2} text="solidní přístup k zákazníkovi" />
          <Pillar number={3} text="udržitelnost a ekologie" />
        </Box>
      </ContentSection>

      <ContentSection>
        <Text as="p">
          Výrobky firmy Arboritec jsou známé jednoduchými aplikačními postupy,
          které v kombinaci se zmíněnými nanotechnologickými aditivy dávají všem
          jejím výrobkům punc kvality.
        </Text>
        <Text as="p">
          Výrazným impulsem k dalšímu rozvoji a vývoji nových produktů je přitom
          zpětná vazba od zákazníků. Právě díky ní jde Arboritec stále vpřed.
        </Text>
        <Text as="p">
          Ve společnosti Arboritec si zakládají na udržitelnosti. Proto jsou
          všechny její výrobky šetrné k životnímu prostředí, bezpečné a
          neobsahují těkavé rozpouštědlové látky.
        </Text>
        <Text as="p">
          Propojením všech těchto pilířů dává firemní filozofie smysl a v duchu
          této filozofie dává smysl naše spolupráce.
        </Text>

        <Box variant="serviceFooter">
          <RebassLink
            variant="linkButton"
            href="https://eshop.frypo.cz/"
            target="_blank"
          >
            Prohlédnout si produkty Arboritec
          </RebassLink>

          <HeroLink dark to="/nabizime/vzorovani">
            Další: Vzorování na míru
          </HeroLink>
        </Box>
      </ContentSection>
    </Layout>
  )
}

export default ArboritecPage
