import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Text } from 'rebass/styled-components'

import Container from './container'
import { HeroLink } from './hero-link'

export const Hero = ({
  image,
  bgPosition,
  title,
  text,
  ctaLabel,
  ctaLink,
  fullHeight,
  leadText,
  sx,
}) => (
  <Box
    variant={fullHeight ? 'heroFullHeight' : 'hero'}
    as="section"
    sx={{
      backgroundImage: `url(${image})`,
      backgroundPosition: bgPosition,
      ...sx,
    }}
  >
    <Box variant="heroOverlay" />

    <Box variant="heroBody">
      <Container sx={{ width: ['auto', 'auto', '1040px'] }}>
        <Box variant="heroContent" sx={{ gap: leadText ? 2 : 4 }}>
          {title && (
            <Heading
              variant={fullHeight ? 'heroTitleBig' : 'heroTitle'}
              as="h1"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}

          {text && (
            <Text
              variant={leadText ? 'heroTextLead' : 'heroText'}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}

          {ctaLabel && ctaLink && <HeroLink to={ctaLink}>{ctaLabel}</HeroLink>}
        </Box>
      </Container>
    </Box>
  </Box>
)

Hero.propTypes = {
  image: PropTypes.string,
  bgPosition: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  fullHeight: PropTypes.bool,
  leadText: PropTypes.bool,
  sx: PropTypes.object,
}

Hero.defaultProps = {
  fullHeight: false,
  leadText: false,
}
